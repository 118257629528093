import {
  Badge,
  Box,
  ChakraProvider,
  Grid,
  Text,
  Heading,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
  theme,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";

type BetData = {
  Person: string;
  Bet: string;
  Group: string;
  Win: string; // Add this line
};

const betData: BetData[] = [
  {
    Person: "CJ",
    Bet: "Jordan Mason Anytime",
    Group: "Quick Pickers",
    Win: "Pending",
  },
  { Person: "Cole", Bet: "", Group: "", Win: "Pending" },
  {
    Person: "Tomdude",
    Bet: "Steelers spread",
    Group: "Late Pickers",
    Win: "Pending",
  },
  { Person: "Tito", Bet: "Packers ML", Group: "Quick Pickers", Win: "Pending" },
  { Person: "Asvestas", Bet: "", Group: "", Win: "Pending" },
  {
    Person: "Potance",
    Bet: "WAS BAL Over 51",
    Group: "Late Pickers",
    Win: "Pending",
  },
  { Person: "Hunter", Bet: "Lions -3", Group: "Quick Pickers", Win: "Pending" },
  {
    Person: "Parker",
    Bet: "Chargers -3",
    Group: "Quick Pickers",
    Win: "Pending",
  },
  {
    Person: "Joe",
    Bet: "Devonta Smith ATD",
    Group: "Late Pickers",
    Win: "Pending",
  },
  {
    Person: "Grizz",
    Bet: "Lamb Anytime",
    Group: "Quick Pickers",
    Win: "Pending",
  },
  { Person: "Fonzi", Bet: "Jags ML", Group: "Quick Pickers", Win: "Pending" },
  {
    Person: "Darling Sunshine",
    Bet: "49ers Seahawks Under 49.5",
    Group: "Late Pickers",
    Win: "Pending",
  },
  { Person: "B-Mac", Bet: "Bears ML", Group: "Late Pickers", Win: "Pending" },
  {
    Person: "Broker",
    Bet: "Kittle Anytime",
    Group: "Quick Pickers",
    Win: "Pending",
  },
  { Person: "Peifer", Bet: "Bucs ML", Group: "Quick Pickers", Win: "Pending" },
  {
    Person: "Gilman",
    Bet: "Saquon Anytime",
    Group: "Quick Pickers",
    Win: "Pending",
  },
  { Person: "Scott", Bet: "Bijan ATD", Group: "Late Pickers", Win: "Pending" },
  {
    Person: "Keller",
    Bet: "Washington +6.5",
    Group: "Late Pickers",
    Win: "Pending",
  },
  { Person: "Fintel", Bet: "", Group: "", Win: "Pending" },
  {
    Person: "Schneider",
    Bet: "Jets ML",
    Group: "Late Pickers",
    Win: "Pending",
  },
  {
    Person: "Kampbell",
    Bet: "Falcons -6",
    Group: "Late Pickers",
    Win: "Pending",
  },
  { Person: "Goodman", Bet: "", Group: "", Win: "Pending" },
  {
    Person: "Zander",
    Bet: "Montgomery Anytime",
    Group: "Quick Pickers",
    Win: "Pending",
  },
  {
    Person: "Rister",
    Bet: "Texans Spread",
    Group: "Late Pickers",
    Win: "Pending",
  },
  { Person: "Dang", Bet: "", Group: "", Win: "Pending" },
  { Person: "Luke", Bet: "Bills -2.5", Group: "Late Pickers", Win: "Pending" },
];

export const App = () => {
  const groupY = betData.filter((bet) => bet.Group === "Quick Pickers");
  const groupY2 = betData.filter((bet) => bet.Group === "Late Pickers");

  const renderTable = (data: BetData[], groupName: string, footer: string) => (
    <Box mb={8}>
      <Heading as="h2" size="lg" mb={4} fontWeight="light" textAlign="left">
        {groupName}
      </Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Person</Th>
            <Th>Bet</Th>
            <Th>Win</Th> {/* Add this line */}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row, index) => (
            <Tr key={index}>
              <Td>{row.Person}</Td>
              <Td>{row.Bet}</Td>
              <Td>
                {row.Win === "Pending" ? (
                  <Badge colorScheme="gray">Pending</Badge>
                ) : (
                  "Something"
                )}
              </Td>{" "}
              {/* Add this line */}
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td colSpan={3}>
              <Text textAlign="center">{footer}</Text>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </Box>
  );

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <VStack spacing={8} align="stretch" maxW="1000px" mx="auto">
            <Heading as="h1" size="2xl" mb={8}>
              Week 6
            </Heading>
            {renderTable(groupY, "Quick Pickers", "To win $2,420")}
            {renderTable(groupY2, "Late Pickers", "To Win $1,536")}
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};
